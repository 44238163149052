import { Resources, useResource } from "Translations/Resources";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import { UseFormReturn } from "react-hook-form";
import { Typography, Box } from "@mui/material";
import { LoadingWrapper } from "Components/Orders/Draft/LoadingWrapper";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { BlRadioButtons } from "Components/Shared/Inputs/Form/BlRadioButtons";
import {
  EnterpriseServiceListItemDto,
  OrderPaymentFrequency,
  OrderPeriodicity,
  UserSupplierCompanyListItemDto,
} from "Api/Api";
import { CodeListItem } from "Models/ICodeListDto";
import { enumToArray } from "Utils/ObjectUtils";
import { usePriceData } from "Hooks/Orders/usePriceData";
import { formatCurrency } from "Utils/CurrencyUtils";
import { useTaxLabels } from "Hooks/useWithTax";
import styled from "styled-components";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { PaymentFrequencyDateFields } from "Components/Orders/Draft/PaymentFrequencyDateFields";
import { getDefaultDateFromDateToValues } from "Utils/Order/PaymentFrequencyUtils";
import { differenceInMonths } from "date-fns";
import { useMonthTranslations } from "Hooks/Resources/useMonthTranslations";

const PageResources = Resources.Orders.Detail.Payment;

const RadioButtonWrapper = styled(Box)`
  width: 100%;
  span {
    margin-bottom: ${props => props.theme.spacing(1)};
  }

  .Mui-checked {
    display: flex;
    align-self: flex-start;
  }

  .bl-radio-buttons__label-content {
    margin-top: ${props => props.theme.spacing(0.2)};
  }
`;

type Props = {
  form: UseFormReturn<OrderFormModel>;
  isLoading: boolean;
  isReadOnly: boolean;
  enterpriseServices: EnterpriseServiceListItemDto[];
  companies: UserSupplierCompanyListItemDto[];
};

export const PaymentFields: React.FunctionComponent<Props> = props => {
  const { form, isLoading, isReadOnly, enterpriseServices, companies } = props;
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = form;
  const { t } = useResource();
  const { withTax, withoutTax } = useTaxLabels();

  const { currencyCode, priceWithTax, priceWithoutTax, isVatUsed } =
    usePriceData(form, enterpriseServices, companies);

  const price = isVatUsed ? priceWithTax : priceWithoutTax;

  const taxLabel = isVatUsed ? withTax : (s: string) => s;

  const paymentFrequency = watch("order.paymentFrequency");
  const paymentPeriodicity = watch("order.paymentPeriodicity");
  const isIndefiniteEnd = watch("order.isIndefiniteEnd");

  const monthDiff =
    differenceInMonths(
      watch("order.paymentFrequencyDateTo") ?? new Date(),
      watch("order.paymentFrequencyDateFrom") ?? new Date(),
    ) + 1;
  const overallPriceWithoutTax = monthDiff * priceWithoutTax;
  const overallPriceWithTax = monthDiff * priceWithTax;
  const tMonth = useMonthTranslations();

  if (paymentPeriodicity === OrderPeriodicity.Single) {
    return null;
  }

  return (
    <StyledContentWrapper>
      <Typography variant="h2" marginBottom={2}>
        {t(PageResources.Title)}
      </Typography>

      <Typography mb={1}>{t(PageResources.Label)}</Typography>

      <LoadingWrapper isLoading={isLoading}>
        <RadioButtonWrapper>
          <BlRadioButtons
            control={control}
            row={false}
            codeList={enumToArray(OrderPaymentFrequency)
              .filter(x => x !== OrderPaymentFrequency.Single)
              .map(
                x =>
                  ({
                    code: x,
                    name: formatName(
                      x as OrderPaymentFrequency,
                      price,
                      currencyCode,
                      taxLabel,
                      t,
                    ),
                    endAdornment: (
                      <Box mt={0.25}>
                        <BlDefaultTooltip
                          title={t(PageResources.RadioButtons.Tooltip[x])}
                        />
                      </Box>
                    ),
                    description: (
                      <>
                        {paymentFrequency === x && (
                          <PaymentFrequencyDateFields form={form} />
                        )}
                      </>
                    ),
                  }) as CodeListItem,
              )}
            errors={errors}
            name="order.paymentFrequency"
            disabled={isReadOnly}
            useCodesAsLabels={false}
            onChange={value => {
              const { dateFrom, dateTo } = getDefaultDateFromDateToValues(
                value as OrderPaymentFrequency,
              );
              setValue("order.paymentFrequencyDateFrom", dateFrom);
              setValue("order.paymentFrequencyDateTo", dateTo);
            }}
            slotProps={{
              typography: {
                width: "100%",
              },
            }}
          />
        </RadioButtonWrapper>

        <Typography marginBottom={0.5}>
          {t(
            isIndefiniteEnd
              ? PageResources.MonthPrice
              : PageResources.OverallPrice,
          )}
        </Typography>

        <Typography marginBottom={0.5} fontSize={16} fontWeight={600}>
          {isIndefiniteEnd ? (
            <>{`${formatCurrency(priceWithoutTax, currencyCode)}`}</>
          ) : (
            <>
              {`${tMonth(monthDiff)} * ${formatCurrency(
                priceWithoutTax,
                currencyCode,
              )} = ${withoutTax(
                formatCurrency(overallPriceWithoutTax, currencyCode),
                isVatUsed,
              )}`}
            </>
          )}
        </Typography>

        {isVatUsed && (
          <Typography>
            {withTax(formatCurrency(overallPriceWithTax, currencyCode))}
          </Typography>
        )}
      </LoadingWrapper>
    </StyledContentWrapper>
  );
};

function formatName(
  orderPaymentFrequency: OrderPaymentFrequency,
  price: number,
  currencyCode: string,
  taxLabel: (price: string) => string,
  t: (key: string) => string,
) {
  const pricePerPayment = Math.floor(
    getPricePerPaymentFrequency(price, orderPaymentFrequency),
  );
  const formattedCurrency = formatCurrency(pricePerPayment, currencyCode);
  const postfix = getPostfix(orderPaymentFrequency, t);
  const name = t(Resources.OrderPaymentFrequency[orderPaymentFrequency]);
  return `${name} (${taxLabel(formattedCurrency)}${postfix})`;
}

function getPostfix(
  paymentFrequency: OrderPaymentFrequency,
  t: (key: string) => string,
) {
  if (paymentFrequency === OrderPaymentFrequency.Single) {
    return "";
  }

  return ` / ${t(PageResources.RadioButtons.PeriodPostfix[paymentFrequency])}`;
}

function getPricePerPaymentFrequency(
  price: number,
  paymentFrequency: OrderPaymentFrequency,
) {
  switch (paymentFrequency) {
    case OrderPaymentFrequency.Monthly:
      return price;
    case OrderPaymentFrequency.Quarterly:
      return price * 3;
    case OrderPaymentFrequency.Semiannually:
      return price * 6;
    case OrderPaymentFrequency.Yearly:
      return price * 12;
    default:
      return price;
  }
}
